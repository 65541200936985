export const REPORTS_WEEK_START_STORAGE_KEY = "REPORTS_WEEK_START_STORAGE_KEY";

export const ReportTableRowType = {
  MONEY: "money",
  PERCENTAGE: "percentage",
  AMOUNT: "amount",
  DATE: "date",
  DURATION: "duration",
  TIME: "time",
};

export const StoreReportViewType = {
  WEEK: "week",
  MONTH: "month",
};

export const BackstockReportsType = {
  PULLED: "pulled",
  ADDED: "added",
};

export const LedgerViewMap = {
  sales: [
    { name: "cashTendered", type: "debit" },
    { name: "checkDeposit", type: "debit" },
    { name: "mastercard", type: "debit" },
    { name: "visa", type: "debit" },
    { name: "americanExpress", type: "debit" },
    { name: "discover", type: "debit" },
    { name: "debitCard", type: "debit" },
    { name: "otherCard", type: "debit" },
    { name: "giftCards", type: "debit" },
    { name: "storeCredit", type: "debit" },
    { name: "paypal", type: "debit" },
    { name: "coupons", type: "debit" },
    { name: "houseCharge", type: "debit" },
    { name: "tradeCredit", type: "debit" },
    { name: "internetCreditCard", type: "debit" },
    { name: "giftCardsActivated", type: "credit" },
    { name: "arPaymentsReceivedOnAcct", type: "credit" },
    { name: "depositsReceived", type: "credit" },
    { name: "depositsUsed", type: "debit" },
    { name: "checksIssuedOnSale", type: "credit" },
    { name: "storeCreditIssuedOnSale", type: "credit" },
    { name: "salesNew", type: "credit" },
    { name: "salesUsed", type: "credit" },
    { name: "salesOther", type: "credit" },
    { name: "returnsNew", type: "debit" },
    { name: "returnsUsed", type: "debit" },
    { name: "returnsOther", type: "debit" },
    { name: "taxCollected", type: "credit" },
    { name: "cogsNew", type: "debit" },
    { name: "cogsUsed", type: "debit" },
    { name: "cogsOther", type: "debit" },
    { name: "inventoryNew", type: "credit" },
    { name: "inventoryUsed", type: "credit" },
    { name: "inventoryOther", type: "credit" },
    { name: "returnsCogsNew", type: "credit" },
    { name: "returnsCogsUsed", type: "credit" },
    { name: "returnsCogsOther", type: "credit" },
    { name: "invReturnsNew", type: "debit" },
    { name: "invReturnsUsed", type: "debit" },
    { name: "invReturnsOther", type: "debit" },
    { name: "cashPaidIn", type: "debit" },
    { name: "cashPaidInSpecific", type: "credit" },
    { name: "cashPaidOut", type: "credit" },
    { name: "cashPaidOutExpenses", type: "debit" },
    { name: "openingCash", type: "credit" },
    { name: "nextDayOpen", type: "debit" },
    { name: "changeInOpenCash", type: "dual" },
    { name: "freight", type: "credit" },
    { name: "cashVariance", type: "dual_db" }, // debit if positive
    { name: "totalVariance", type: "dual_cr" }, // credit if positive
  ],
  buys: [
    { name: "buysTotal", type: "debit" },
    { name: "buysCash", type: "credit" },
    { name: "buysChecks", type: "credit" },
    { name: "buysStoreCredit", type: "credit" },
    { name: "buysTradeCredit", type: "credit" },
    { name: "buysOffsite", type: "credit" },
    { name: "buysSubtotal", type: "both", total: true },
    { name: "subtotal", type: "both", total: true },
    { name: "total", type: "both", total: true, padTop: true },
    { name: "auditCalc", type: "debit", total: true },
  ],
};

export const JournalViewMap = {
  sales: [
    { name: "salesCash", type: "debit" },
    { name: "salesChecks", type: "debit" },
    { name: "salesCreditCards", type: "debit" },
    { name: "salesDebitCards", type: "debit" },
    { name: "salesPaypal", type: "debit" },
    { name: "salesInternetCreditCards", type: "debit" },
    { name: "salesGiftCardsRedeemed", type: "debit" },
    { name: "salesStoreCreditRedeemed", type: "debit" },
    { name: "salesTradeCreditRedeemed", type: "debit" },
    { name: "salesCouponsRedeemed", type: "debit" },
    { name: "salesLayawayRedeemed", type: "debit" },
    { name: "salesHouse", type: "debit" },
    { name: "salesFreight", type: "credit" },
    { name: "salesVariance", type: "dual" },
    { name: "salesLayawayDeposit", type: "credit" },
    { name: "salesStoreCreditIssued", type: "credit" },
    { name: "salesChecksIssued", type: "credit" },
    { name: "salesGiftCardsActivated", type: "credit" },
    { name: "salesNew", type: "credit" },
    { name: "salesUsed", type: "credit" },
    { name: "salesOther", type: "credit" },
    { name: "salesReturnsNew", type: "debit" },
    { name: "salesReturnsUsed", type: "debit" },
    { name: "salesReturnsOther", type: "debit" },
    { name: "salesTaxCollected", type: "credit" },
  ],
  buys: [
    { name: "buysUsedInv", type: "debit" },
    { name: "buysCash", type: "credit" },
    { name: "buysChecks", type: "credit" },
    { name: "buysStoreCredit", type: "credit" },
    { name: "buysTradeCredit", type: "credit" },
    { name: "buysOffsite", type: "credit" },
    { name: "buysSubtotal", type: "both", total: true },
  ],
  inv: [
    { name: "invCogsNew", type: "debit" },
    { name: "invCogsUsed", type: "debit" },
    { name: "invCogsOther", type: "debit" },
    { name: "invNew", type: "credit" },
    { name: "invUsed", type: "credit" },
    { name: "invOther", type: "credit" },
    { name: "invCogsReturnsNew", type: "credit" },
    { name: "invCogsReturnsUsed", type: "credit" },
    { name: "invCogsReturnsOther", type: "credit" },
    { name: "invReturnsNew", type: "debit" },
    { name: "invReturnsUsed", type: "debit" },
    { name: "invReturnsOther", type: "debit" },
    { name: "invSubtotal", type: "both", total: true },
    { name: "total", type: "both", total: true, padTop: true },
  ],
};
