<template>
  <RaiSetups v-model="activeSetup">
    <RaiSetup
      :title="$t('report.setup.weekStart.title')"
      hide-next
      v-on="$listeners"
    >
      <RaiSetupToggle
        v-model="weekStartsMonday"
        :title="$t('report.setup.weekStart.toggle.title')"
        :subtitle="$t('report.setup.weekStart.toggle.subtitle')"
      />
      <p>
        {{ $t("report.setup.weekStart.toggle.info") }}
        <strong>{{ currentWeekStart }}</strong>
      </p>
    </RaiSetup>
  </RaiSetups>
</template>

<script>
import { REPORTS_WEEK_START_STORAGE_KEY } from "../constants";
import { RaiSetups, RaiSetup, RaiSetupToggle } from "@/core-ui";

export default {
  name: "ReportsSetupView",
  components: {
    RaiSetups,
    RaiSetup,
    RaiSetupToggle,
  },
  props: {
    storeId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      activeSetup: 0,
      weekStartValue:
        window.localStorage.getItem(REPORTS_WEEK_START_STORAGE_KEY) === "true",
    };
  },
  computed: {
    currentWeekStart() {
      return this.weekStartsMonday
        ? this.$t("report.setup.monday")
        : this.$t("report.setup.sunday");
    },
    weekStartsMonday: {
      get() {
        this.weekStartValue; // Just to make this reactive -> TODO - refactor
        return (
          window.localStorage.getItem(REPORTS_WEEK_START_STORAGE_KEY) === "true"
        );
      },
      set(value) {
        window.localStorage.setItem(
          REPORTS_WEEK_START_STORAGE_KEY,
          value ? "true" : "false"
        );
        this.weekStartValue = value;
      },
    },
  },
};
</script>
